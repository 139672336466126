@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .ga-layout {
    display: grid;
    height: 100svh;
    align-content: baseline;
    grid-template-rows: auto 1fr auto;
  }

  .ga-user-layout {
    display: grid;
    height: 100svh;
    align-content: baseline;
    grid-template-rows: 1fr auto;
  }

  .ga-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 1279px) {
    .ga-container {
      padding-left: theme('size.3');
      padding-right: theme('size.3');
    }
  }

  @media only screen and (min-width: 1280px) {
    .ga-container {
      padding-left: theme('size.12');
      padding-right: theme('size.12');
      max-width: 1648px;
    }
  }
  @media only screen and (min-width: 1919px) {
    .ga-container {
      max-width: 1856px;
    }
  }
}
