@tailwind base;

@layer base {
  body {
    color: theme('colors.blue.200');
    font-weight: theme('fontWeight.normal');
    background-color: theme('colors.white');
  }

  *:focus,
  *:focus-visible {
    @apply !outline-none focus:!ring-0 focus:!ring-offset-0;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
