@tailwind components;

@layer components {
  /* Panel */
  .ga-panel {
    display: grid;
  }

  .ga-panel .ga-panel-body {
    display: grid;
    background-color: theme('colors.grey.100');
    padding-left: theme('size.12');
    padding-right: theme('size.12');
    padding-top: theme('size.12');
    padding-bottom: theme('size.12');
    border-bottom: 1px solid theme('colors.blue.200/0.1');
  }

  .ga-panel .ga-panel-body:first-child {
    padding-top: theme('size.12');
    border-top-right-radius: theme('borderRadius.3xl');
    border-top-left-radius: theme('borderRadius.3xl');
  }
  .ga-panel .ga-panel-body:last-child {
    border-bottom: none;
    border-bottom-right-radius: theme('borderRadius.3xl');
    border-bottom-left-radius: theme('borderRadius.3xl');
  }

  @media only screen and (max-width: 1279px) {
    .ga-panel {
      padding-top: theme('size.6');
      padding-bottom: theme('size.6');
    }

    .ga-panel .ga-panel-body {
      padding-left: theme('size.6');
      padding-right: theme('size.6');
    }
  }

  /* Card */

  .ga-card {
    background-color: theme('colors.white');
    border-radius: theme('borderRadius.3xl');
    padding: theme('size.12');
  }

  @media only screen and (max-width: 1279px) {
    .ga-card {
      padding: theme('size.6');
    }
  }

  /* Pill */

  .ga-pill {
    display: flex;
    justify-content: center;
    align-items: center;
    height: theme('size.8');
    padding-left: theme('spacing.5');
    padding-right: theme('spacing.5');
    font-size: theme('fontSize.14');
    font-weight: theme('fontWeight.medium');
    background-color: theme('colors.blue.50/0.1');
    color: theme('colors.gray.900');
    border-radius: theme('borderRadius.3xl');
    /* truncate */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Table */

  .ga-table {
    width: 100%;
    overflow: hidden;
    background-color: theme('colors.white');
    border-radius: theme('borderRadius.3xl');
  }

  .ga-table :not(thead) tr:first-child {
    border-top: 1px solid theme('colors.black/0.1');
  }

  .ga-table :not(thead) tr:not(:first-child) {
    border-top: 1px solid theme('colors.black/0.05');
  }

  .ga-table th {
    font-size: theme('fontSize.14');
    font-weight: theme('fontWeight.medium');
    line-height: theme('lineHeight.6');
    padding: theme('space.5');
    text-align: left;
    text-wrap: nowrap;
  }

  .ga-table td {
    font-size: theme('fontSize.16');
    font-weight: theme('fontWeight.medium');
    line-height: theme('lineHeight.6');
    color: theme('colors.blue.200/0.5');
    padding: theme('space.3') theme('space.5');
    text-align: left;
    text-wrap: nowrap;
  }

  /* Content Editable */
  .ga-content-editable {
    font-family: inherit;
    display: flex;
    flex-wrap: wrap;
  }
  .ga-content-editable .phoneme-tag,
  .ga-content-editable .break-tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: theme('size.8');
    gap: theme('size.1');
    padding-left: theme('spacing.2');
    /* padding-right: theme('spacing.2'); */
    font-size: theme('fontSize.14');
    font-weight: theme('fontWeight.bold');
    background-color: theme('colors.blue.50/0.1');
    color: theme('colors.gray.900');
    border-radius: theme('borderRadius.3xl');
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.35s ease;
    cursor: pointer;
    user-select: none;
  }

  .ga-content-editable .phoneme-tag:hover {
    background-color: theme('colors.blue.50/0.15');
  }

  .ga-user-tag {
    text-decoration: underline;
    font-weight: theme('fontWeight.bold');
  }
}
